<template>
  <iframe class="price-iframe" src="https://www.cloakone.com/pricing.html" frameborder="0"></iframe>
</template>

<script>
export default {}
</script>

<style scoped>
.price-iframe {
  min-width: 100%;
  min-height: 100vh;
}
</style>
